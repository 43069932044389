export const methods = {
  yooMoney: 'YooMoney',
  tinkoff: 'Тинькофф',
  mandarin: 'Мандарин',
  stripe: 'Stripe',
  cards: 'Оплата картой',
  recurrent: 'Автоплатёж'
}

export default {
  title: 'Платежи',

  titles: {
    individual: 'Физические лица',
    legal: 'Юридические лица',
    threshold: 'По порогу',
    frequency: 'По времени',
    billings: 'Финансовые операции',
    paymentLogs: 'Журнал запросов'
  },

  labels: {
    amount: 'Сумма',
    method: 'Метод',
    frequency: '',
    status: 'Статус',
    paymentType: 'Тип платежа',
    sum: 'Сумма',
    owner: 'Владелец',

    methods: {
      tinkoff: {
        TerminalKey: 'Ключ терминала',
        Password: 'Пароль',
        ekamToken: 'EKAM токен'
      },

      mandarin: {
        merchantId: 'ID продавца',
        secret: 'Секретный ключ',
        ekamToken: 'EKAM токен',
        includeFiscalInformation: 'Включить фискальную информацию'
      },

      stripe: {
        apikey: 'API ключ',
        usd_price_id: 'ID цены для USD',
        eur_price_id: 'ID цены для EUR'
      }
    }
  },

  recurrentTypes: {
    once: 'Единоразово',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно'
  },

  contents: {
    legal: 'Для выставления счета просьба написать на <a href="mailto:support@sigmasms.ru">support@sigmasms.ru</a>.',
    payment: {
      result: {
        success: 'Платёж успешно проведён.<br>Денежные средства скоро будут зачислены на ваш счёт.',
        fail: 'Не удалось завершить платёж.<br>Пожалуйста, обратитесь к менеджеру для решения этой проблемы.'
      }
    },
    step: {
      first: 'Способ оплаты',
      individual: 'Способ оплаты',
      legal: 'Выставление счета',
      cards: 'Оплата картой',
      yooMoney: 'YooMoney',
      recurrent: 'Автоплатёж'
    }
  },

  hints: {
    delay: 'Баланс зачисляется в течение 5 минут.',
    commission: 'Комиссия банка:',
    commissions: 'комиссии',
    yooMoney: 'Оплата с помощью платёжной системы YooMoney, комиссия составит 2%.',
    noOneFound: 'Не найдено ни одного платежа',
    nextPaymentAt: 'Следующий платёж',
    addPayment: 'Добавить платёж',
    offer: 'Я принимаю условия договора оферты.',
    goToPayment: 'Перейти к оплате',
    notFound: 'Не найдено ни одного платежа',
    individual: 'Для пополнения баланса личного кабинета кредитной картой свяжитесь с нами любым удобным способом.',
    owner: 'Владелец',
    due: 'Fee: {due}%',
    sum: 'Зачисление в течение 5 минут',
    fee: {
      PC: '1% комиссии',
      AC: '3% комиссии'
    }
  },

  types: {
    individual: 'Как физическое лицо',
    legal: 'Как юридическое лицо'
  },

  methods,

  buttons: {
    add: {
      funds: 'Пополнить баланс',
      payment: 'Настроить автоплатеж'
    },
    request: {
      payment: 'Запросить платёж'
    },
    billings: 'История финансовых операций',
    paymentLogs: 'Журнал запросов',
    refund: 'Возврат платежа'
  },

  links: {
    attachInvoice: 'Приложить счет',
    attachInvoiceTo: 'Приложить счет на {title}'
  },

  statuses: {
    init: 'Инициализирован',
    completed: 'Проведён',
    refundRequested: 'Запрошен возврат',
    refunded: 'Возмещён',
    partialRefunded: 'Частично возмещён',
    error: 'Ошибка'
  },

  tabs: {
    payments: 'Платежи',
    recurrentPayments: 'Автоплатежи'
  },

  yooMoney: {
    paymentTypes: {
      PC: 'Кошелёк YooMoney',
      AC: 'Банковская карт',
      MC: 'Счёт мобильного'
    },
    warning: 'Чтобы идентифицировать ваш платеж, вам необходимо написать нам на почту <a href="mailto:a1@sigmasms.ru">a1@sigmasms.ru</a>.<br>В письма укажите ваш логин и сумму платежа.<br>Также просьба приложить чек об оплате.'
  }
}
