import Vue from 'vue'

import { get } from 'lodash'
import { Macro } from '@sigma-legacy-libs/cache'

import { borderStyles, colors, icons, isUUID, nestjsServices, numberToPxOrString, permissionPresets } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, service, id, params) => {
  const path = []
  if (Object.keys(nestjsServices).includes(service)) {
    path.push('n')
  }
  path.push(service)

  return await Vue.$GRequest.get(path.join('/'), id, params)
})

const className = 'sticker'

export default {
  name: 'Sticker',

  props: {
    value: {
      type: [ String, Number, Object ],
      default: () => undefined
    },

    service: {
      type: String,
      default: 'users'
    },
    pathToId: {
      type: String,
      default: 'id'
    },
    pathToTitle: {
      type: String,
      default: 'title'
    },

    label: [ String, Number ],

    borderColor: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },
    borderStyle: {
      type: String,
      default: borderStyles.solid,
      validator: value => {
        return Object.values(borderStyles).includes(value)
      }
    },

    icon: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(icons).includes(value)
      }
    },
    iconSVG: {
      type: String,
      default: undefined
    },
    iconSize: {
      type: Number,
      default: 20
    },
    iconTooltip: {
      type: String,
      default: undefined
    },
    tooltip: {
      type: String,
      default: undefined
    },
    tooltipTimeout: {
      type: Number,
      default: undefined
    },
    translatePath: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },
    backgroundColor: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    to: {
      type: Object,
      default: undefined
    },

    onClick: {
      type: Function,
      default: undefined
    },

    target: {
      type: String,
      default: undefined
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    button: {
      type: Object,
      default: undefined
    },

    copy: {
      type: Boolean,
      default: false
    },

    sensitivity: {
      type: Boolean,
      default: false
    },

    minWidth: [ String, Number ],
    maxWidth: [ String, Number ],
    width: {
      type: [ String, Number ],
      default: 'auto'
    }
  },

  data() {
    return {
      data: undefined,

      loading: false,
      showTooltip: false,
      buttonCallback: false
    }
  },

  computed: {
    title() {
      if (!isUUID(this.value) && typeof this.value !== 'object') {
        return this.value
      }

      const title = get(this.data, this.pathToTitle, '')

      if (!!title && this.translatePath) {
        return this.getTranslate(`${this.translatePath}.${title}`)
      }

      return title
    },

    hasTo() {
      return !!this.$to && Object.keys(this.$to).length > 0
    },
    hasIcon() {
      return !!this.icon || !!this.iconSVG || !!this.$scopedSlots.icon || !!this.$slots.icon
    },
    hasContent() {
      return !!this.title || !!this.label
    },

    $button() {
      const result = {}
      if (this.button || this.copy) {
        result.size = 20

        if (this.button) {
          result.color = this.button.color
          result.icon = this.button.icon
          result.tooltip = this.button.tooltip
          result.callback = this.button.callback
          result.disabled = this.button.disabled
        } else {
          result.color = colors.grey
          result.icon = icons.content_copy
          result.tooltip = this.getTranslate('misc.buttons.copy')
          result.callback = () => navigator.clipboard.writeText(this.title)
        }

        if (this.buttonCallback) {
          result.color = colors.success
          result.icon = icons.check
        }

        if (this.disabled) {
          result.disabled = this.disabled
        }
      }

      return result
    },
    buttonDirectives() {
      const result = []
      if (this.$button.tooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.$button.tooltip,
            placement: 'top'
          }
        })
      }

      return result
    },

    gridTemplateColumns() {
      if (this.hasIcon && this.hasContent) {
        return 'repeat(2, auto)'
      }

      return 'auto'
    },

    $to() {
      if (this.readonly) {
        return
      }

      if (this.to) {
        return this.to
      }

      if (this.service && this.data) {
        const id = get(this.data, this.pathToId)
        if (id) {
          return {
            name: `${this.service}.single`,
            params: { id }
          }
        }
      }
    },

    $directives() {
      const result = []
      if (this.tooltip || this.showTooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip || this.title,
            timeout: this.tooltipTimeout,
            placement: 'top'
          }
        })
      }

      return result
    },

    $class() {
      return {
        [`${className}`]: true,

        [`${className}--icon`]: this.hasIcon,
        [`${className}--empty`]: !this.hasContent,
        [`${className}--link`]: this.hasTo || !!this.onClick,
        [`${className}--button`]: this.copy || !!this.button,
        [`${className}--loading`]: this.loading,
        [`${className}--disabled`]: this.disabled,
        [`${className}--sensitivity`]: this.sensitivity,

        [`${className}--${this.color}`]: !!this.color,
        [`${className}-border--${this.borderColor}`]: !!this.borderColor,
        [`${className}-background--${this.backgroundColor}`]: !!this.backgroundColor
      }
    },
    $style() {
      return {
        borderStyle: this.$borderStyle,
        minWidth: numberToPxOrString(this.minWidth),
        maxWidth: numberToPxOrString(this.maxWidth),
        width: numberToPxOrString(this.width)
      }
    },
    $borderStyle() {
      return this.hasTo || !!this.onClick ? borderStyles.solid : borderStyles.dashed
    }
  },

  watch: {
    value: {
      handler() {
        this.getData()
        this.setShowTooltip()
      },
      deep: true
    },

    tooltip() {
      this.setShowTooltip()
    }
  },

  mounted() {
    this.getData()
    this.setShowTooltip()

    window.addEventListener('resize', () => setTimeout(this.setShowTooltip, 200))
  },

  updated() {
    this.setShowTooltip()
  },

  beforeDestroy() {
    window.removeEventListener('resize', () => setTimeout(this.setShowTooltip, 200))
  },

  methods: {
    setShowTooltip() {
      const title = this.$refs.title
      if (title) {
        if (parseInt(title.offsetWidth) < parseInt(title.scrollWidth)) {
          this.showTooltip = true
        } else {
          this.showTooltip = false
        }
      }
    },

    async getData() {
      try {
        if (this.value) {
          if (isUUID(this.value)) {
            if (this.checkPermissions(`advanced.${this.service}.get`, permissionPresets.meUp)) {
              this.loading = true
              const { data } = await cachedGet(`${this.service}:${this.value}`, this.service, this.value, this.params)
              if (data) {
                this.data = data
              }
              this.loading = false
            }
          } else if (typeof this.value === 'object') {
            this.data = this.value
          }
        }
      } catch (error) {
      }
    },

    clickHandler(event) {
      if (this.onClick) {
        this.onClick(event)
      }
    },

    buttonClickHandler() {
      this.$button.callback()
      if (this.copy) {
        this.addSnackbar({
          text: this.getTranslate('misc.copied'),
          type: 'success'
        })
      }

      this.buttonCallback = true
      setTimeout(() => {
        this.buttonCallback = undefined
      }, 1000)
    }
  },

  render
}
