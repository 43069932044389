export default {
  key: 'Ключ',
  value: 'Значение',
  all: 'Все',
  from: 'Из',
  by: 'От',
  at: 'в',
  in: 'в',
  if: 'Если',
  to: 'В',
  of: 'Из',
  and: 'И',
  or: 'Или',
  yet: 'Ещё',
  new: 'Новые',
  isActive: 'Активно',
  search: 'Поиск',
  loadMore: 'Загрузить еще',
  documentation: 'Документация',
  pay: 'Оплатить',
  payment: 'Оплата',
  contacts: 'Контакты',
  statistic: 'Статистика',
  refreshSite: 'Обновите страницу, чтобы загрузить новую версию сайта.',
  createdAt: 'Создано',
  updatedAt: 'Обновлено',
  total: 'Итого',
  true: 'Да',
  false: 'Нет',
  empty: '',
  verified: 'Подтвержден',
  advanced: 'Дополнительно',
  owner: 'Владелец',
  symbols: 'Символов',
  unavailable: 'Временно недоступно',
  clear: 'Очистить',
  copied: 'Скопировано',
  nothingFound: 'Ничего не найдено',
  sentToday: 'Отправлено сегодня',
  filteredBy: 'Фильтр по {filter}:',
  presets: 'Пресеты',
  chooseTypes: 'Выбрать типы',
  understand: 'Понятно',
  price: 'Цена',
  current: 'Текущая',
  previous: 'Предыдущая',
  sourceData: 'Исходные данные',
  attention: 'Внимание!',

  titles: {
    confirm: {
      clone: 'Клонирование'
    }
  },

  contents: {
    confirm: {
      clone: 'Вы уверены, что хотите клонировать эту сущность?'
    }
  },

  hints: {
    countriesImportCSV: 'Импорт поддерживает только CSV файлы с разделителем точка с запятой.',
    fromNotFound: 'Компонент формы не найден.',
    componentNotFound: 'Компонент не найден.',

    need: {
      save: 'Необходимо сохранить изменения.'
    }
  },

  theme: {
    title: 'Тема',
    light: 'Светлая тема',
    dark: 'Тёмная тема'
  },

  subheaders: {
    general: 'Основное',
    administrative: 'Административный раздел'
  },

  no: {
    data: {
      text: 'Нет доступных данных'
    }
  },

  time: {
    hours: 'часа(ов)',
    minutes: 'минут(ы)',
    seconds: 'секунд(ы)'
  },

  date: {
    from: 'с',
    by: 'по',
    creation: 'Дата создания',
    update: 'Дата обновления',
    days: 'дня(ей)'
  },

  locales: {
    en: 'English',
    ru: 'Русский'
  },

  platforms: {
    windows: 'Windows',
    macintosh: 'Macintosh',
    linux: 'Linux',
    android: 'Android'
  },

  confirm: {
    remove: 'Подтверждение удаления',
    send: 'Подтверждение отправки',
    scheduled: 'Подтверждение планировки'
  },

  pagination: {
    rows: 'Строки',
    perPage: 'Строк на странице:',
    of: 'из'
  },

  buttons: {
    cancel: 'Отменить',
    remove: 'Удалить',
    create: 'Создать',
    edit: 'Редактировать',
    copy: 'Копировать',
    update: 'Обновить',
    retry: 'Повторить',
    upload: 'Загрузить',
    uploadImage: 'Загрузить изображение',
    continue: 'Продолжить',
    add: 'Добавить',
    save: 'Сохранить',
    download: 'Скачать',
    done: 'Готово',
    approve: 'Одобрить',
    reject: 'Отклонить',
    signin: 'Войти',
    signup: 'Регистрация',
    login: 'Войти',
    registration: 'Регистрация',
    back: 'Назад',
    send: 'Отправить',
    ok: 'Хорошо',
    restoring: 'Восстановление',
    customize: 'Настроить',
    addFunds: 'Добавить средств',
    close: 'Закрыть',
    show: 'Показать',
    scheduled: 'Запланировать',
    import: 'Импорт',
    fallback: 'Переотправка',
    refresh: 'Обновить',
    export: 'Экспорт',
    filter: 'Фильтр',
    look: 'Посмотреть',
    bill: 'Выставить счёт',
    passwordEdit: 'Изменить пароль',
    match: 'Сопоставить',
    goto: {
      files: 'Перейти к файлам',
      requestAndFiles: 'Запросить и перейти к файлам'
    },
    reports: {
      request: 'Запросить отчёт',
      get: 'Получить отчёт'
    },
    statistic: 'Статистика',
    clear: 'Очистить',
    pay: 'Оплатить',
    support: 'Поддержка',
    makeRequest: 'Применить фильтр',
    rename: 'Переименовать',
    exports: {
      pdf: 'Экспортировать в PDF'
    },
    choose: 'Выбрать',
    source: 'Исходные данные',
    properties: 'Свойства',
    accept: 'Принять',
    report: 'Сообщить об ошибке',
    yes: 'Да',
    no: 'Нет',
    confirm: 'Подтвердить',
    understand: 'Понятно',
    rules: 'Условия',
    unlock: 'Разблокировать',
    logout: 'Деавторизовать',
    clone: 'Клонировать',
    attach: 'Привязать'
  },

  moderation: {
    pending: 'Ожидание',
    approved: 'Одобрено',
    rejected: 'Отклонено'
  },

  moderationStatus: {
    disabled: 'Отключено',
    requested: 'Запрошено',
    moderation: 'На модерации',
    approved: 'Одобрено',
    rejected: 'Отклонено'
  },

  encodings: {
    utf8: 'UTF-8',
    win1251: 'WIN-1251',
    win1252: 'WIN-1252',
    maccyrillic: 'MacCyrillic'
  },

  delimiters: {
    comma: 'Запятая',
    semicolon: 'Точка с запятой'
  },

  comparators: {
    regexp: 'Регулярное выражение',
    pattern: 'Шаблон',
    lt: 'Меньше чем',
    lte: 'Меньше чем или равно',
    gt: 'Больше чем',
    gte: 'Больше чем или равно',
    eq: 'Равно'
  },

  paths: {
    payload: {
      sender: 'Отправитель',
      text: 'Текст'
    },
    meta: {
      stats: {
        segments: 'Сегменты',
        characters: 'Символы'
      },
      _recipientData: {
        group: 'Группа оператора',
        code: 'Код оператора'
      }
    }
  },

  genders: {
    male: 'Мужской',
    female: 'Женский'
  },

  sudo: {
    data: {
      title: 'Data only for awesome programmers!'
    }
  },

  measure: {
    time: {
      short: {
        ms: 'мс',
        seconds: 'с',
        minutes: 'м',
        hours: 'ч'
      },

      s: 'С',
      sec: 'Сек',
      second: 'Секунда',
      seconds: 'Секунды',
      m: 'М',
      min: 'Мин',
      minute: 'Минута',
      minutes: 'Минуты',
      h: 'Ч',
      hour: 'Час',
      hours: 'Часы',
      template: {
        seconds: '0 секунд | Одна секунда | {count} секунд(ы)',
        minutes: '0 минут | Одна минута | {count} минут(ы)',
        hours: '0 часов | Один час | {count} часов(а)',
        days: '0 дней | Один день | {count} дня(ей)'
      }
    }
  },

  links: {
    documentation: 'Документация',
    platform: 'Платформа',
    api: 'API',
    instruction: 'Инструкция',
    support: 'Контакты',
    to: {
      recurrentPayments: 'Ссылка на автоплатежи'
    }
  },

  fileTypes: {
    documents: 'Документы',
    tables: 'Таблицы',
    images: 'Изображения',
    audio: 'Аудио',
    pdf: 'PDF',
    video: 'Видео'
  },

  emptyList: 'Ничего не найдено',

  entity: {
    info: {
      owner: 'Владелец',
      reseller: 'Реселлер',
      source: 'Источник',
      createdAt: 'Создано',
      updatedAt: 'Обновлено'
    }
  },

  tooltips: {
    clone: 'Клонировать',
    navigationList: {
      sortable: 'Изменить порядок пунктов меню',
      clear: 'Очистить порядок пунктов меню'
    },
    favorites: {
      disabled: 'Максимально доступное количество избранных 4'
    }
  }
}
