export const methods = {
  yooMoney: 'YooMoney',
  tinkoff: 'Tinkoff',
  mandarin: 'Mandarin',
  stripe: 'Stripe',
  cards: 'Pay by cards',
  recurrent: 'Recurrent payment'
}

export default {
  title: 'Payments',

  titles: {
    individual: 'Individual',
    legal: 'Legal',
    threshold: 'By threshold',
    frequency: 'By frequency',
    billings: 'Billings',
    paymentLogs: 'Payment logs'
  },

  labels: {
    amount: 'Amount',
    method: 'Method',
    frequency: '',
    status: 'Status',
    paymentType: 'Payment type',
    sum: 'Sum',
    owner: 'Owner',

    methods: {
      tinkoff: {
        TerminalKey: 'Terminal key',
        Password: 'Password',
        ekamToken: 'EKAM token'
      },

      mandarin: {
        merchantId: 'Merchant ID',
        secret: 'Secret',
        ekamToken: 'EKAM token',
        includeFiscalInformation: 'Include fiscal information'
      },

      stripe: {
        apikey: 'API key',
        usd_price_id: 'USD price ID',
        eur_price_id: 'RUB price ID'
      }
    }
  },

  recurrentTypes: {
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },

  contents: {
    legal: 'To invoice, please write to <a href="mailto:support@sigmasms.ru">support@sigmasms.ru</a>.',
    payment: {
      result: {
        success: 'Payment completed successfully.<br>Your money will be credited to your account soon.',
        fail: 'Could not complete payment.<br>Please contact your manager to resolve this issue.'
      }
    },
    step: {
      first: 'Payment method',
      individual: 'Payment method',
      legal: 'Making an invoice',
      card: 'Pay with card',
      yooMoney: 'YooMoney',
      recurrent: 'Recurrent payment'
    }
  },

  hints: {
    delay: 'Balance credited within 5 minutes.',
    commission: 'Bank fee:',
    commissions: 'fee',
    yooMoney: 'Alternatively, you can pay via YooMoney with 2% fee.',
    noOneFound: 'No one payment found',
    nextPaymentAt: 'Next payment at',
    addPayment: 'Add payment',
    offer: 'I agree to the offer for automatic payment service.',
    goToPayment: 'Go to payment',
    notFound: 'No payments found',
    individual: 'To replenish the balance of your personal account by credit card, contact us in any convenient way.',
    owner: 'Owner',
    due: 'Fee: {due}%',
    sum: 'Credited within 5 minutes.',
    fee: {
      PC: '1% fee',
      AC: '3% fee'
    }
  },

  types: {
    individual: 'As an individual',
    legal: 'As a company'
  },

  methods,

  buttons: {
    add: {
      funds: 'Add funds',
      payment: 'Add recurrent payment'
    },
    request: {
      payment: 'Request payment'
    },
    billings: 'Billings history',
    paymentLogs: 'Payment logs',
    refund: 'Refund payment'
  },

  links: {
    attachInvoice: 'Attach invoice',
    attachInvoiceTo: 'Attach an invoice for {title}'
  },

  statuses: {
    init: 'Initialized',
    completed: 'Completed',
    refundRequested: 'Refund requested',
    refunded: 'Refunded',
    partialRefunded: 'Partial refunded',
    error: 'Error'
  },

  tabs: {
    payments: 'Payments',
    recurrentPayments: 'Recurrent payments'
  },

  yooMoney: {
    paymentTypes: {
      PC: 'YooMoney wallet',
      AC: 'Bank card',
      MC: 'Mobile account'
    },
    warning: 'To identify your payment, please write to <a href="mailto:a1@sigmasms.ru">a1@sigmasms.ru</a>.<br>Specify your login and payment amount in the letter.<br>Please attach a receipt.'
  }
}
