import proxy from '@sigma-legacy-libs/g-proxy'

import { createPopper } from '@popperjs/core'

import { components, isChildOf, numberToPxOrString, size } from '@/utils'

import render from './render'
import { cloneDeep, isEqual } from 'lodash'

export default {
  name: components.menu,

  mixins: [ proxy(), size ],

  props: {
    attach: null,

    placement: {
      type: String,
      default: 'bottom-start',
      validator: value => {
        return !!~[
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end'
        ].indexOf(value)
      }
    },

    strategy: {
      type: String,
      default: 'absolute',
      validator: value => {
        return !!~[ 'absolute', 'fixed' ].indexOf(value)
      }
    },

    overflowY: {
      type: String,
      default: 'visible'
    },
    overflowX: {
      type: String,
      default: 'visible'
    },

    offsetSkidding: {
      type: Number,
      default: 0
    },
    offsetDistance: {
      type: Number,
      default: 0
    },

    closeOnContentClick: {
      type: Boolean,
      default: true
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },

    width: {
      type: [ String, Number ],
      default: 'auto'
    },

    disabled: Boolean,
    rounded: Boolean,
    transparent: Boolean,

    sameWidth: Boolean
  },

  data() {
    return {
      popperInstance: undefined,

      parentHeight: 0
    }
  },

  computed: {
    $attach() {
      return this.attach || this.$refs.activator
    },

    modifiers() {
      const result = [
        {
          name: 'offset',
          options: {
            offset: ({ placement }) => {
              if (this.proxy && this.$attach && placement.includes('top') && !this.$refs.activator.clientHeight) {
                return [ this.offsetSkidding, this.$parent.$el.clientHeight ]
              } else {
                return [ this.offsetSkidding, this.offsetDistance ]
              }
            }
          }
        }
      ]

      if (this.sameWidth) {
        result.push({
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: [ 'computeStyles' ],
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
          }
        })
      }

      return result
    },

    options() {
      return {
        placement: this.placement,
        modifiers: this.modifiers,
        strategy: this.strategy
      }
    },

    styles() {
      return {
        'overflow-y': this.overflowY,
        'overflow-x': this.overflowX,
        'min-height': numberToPxOrString(this.minHeight),
        'max-height': numberToPxOrString(this.maxHeight),
        height: numberToPxOrString(this.height),
        'min-width': numberToPxOrString(this.minWidth),
        'max-width': numberToPxOrString(this.maxWidth),
        width: numberToPxOrString(this.width)
      }
    }
  },

  watch: {
    proxy(value) {
      if (value) {
        this.show()
      } else {
        this.hide()
      }
    },

    parentHeight() {
      this.update()
    }
  },

  mounted() {
    window.addEventListener('resize', () => setTimeout(this.update, 200))

    if (document.body) {
      if (this.closeOnClick) {
        document.body.addEventListener('click', this.clickHandler)
      }
    }

    this.observeParentHeight()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.update)
    document.body.removeEventListener('click', this.clickHandler)

    this.hide()
  },

  methods: {
    watchProxyHandler() {
      const proxy = cloneDeep(this.proxy)
      if (isEqual(this.value, proxy)) {
        return
      }
    },

    create() {
      this.popperInstance = createPopper(
        this.$attach,
        this.$refs.content,
        this.options
      )

      this.$nextTick(() => {
        this.update()
      })
    },
    update() {
      if (this.popperInstance) {
        this.popperInstance.update()
      }
    },
    destroy() {
      if (this.popperInstance) {
        this.popperInstance.destroy()
        this.popperInstance = undefined
      }
    },

    show() {
      if (this.$refs.content) {
        this.create()
        document.body.append(this.$refs.content)
      }
    },

    hide() {
      this.destroy()
      this.$refs.content.remove()
      this.$emit('destroy')
    },

    checkForDisabled(target) {
      while (target) {
        if (
          target.className &&
          (typeof target.className === 'string' || Array.isArray(target.className)) &&
          (~target.className.indexOf('disabled') || target.hasAttribute('disabled'))
        ) {
          return true
        }
        target = target.parentElement
      }

      return false
    },

    contentClickHandler(event) {
      event.stopPropagation()

      if (!!this.popperInstance && this.checkForDisabled(event.target)) {
        return
      }

      if (this.closeOnContentClick) {
        this.proxy = false
      }
    },

    clickHandler(event) {
      if (!this.disabled && !isChildOf(event.target, this.$refs.holder)) {
        this.proxy = false
      }
    },

    observeParentHeight() {
      if (this.$parent) {
        const parentResizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            this.parentHeight = entry.target.offsetHeight
          }
        })
        parentResizeObserver.observe(this.$parent.$el)
      }
    }
  },

  render
}
